import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  ButtonWrapper,
  Count,
  // CloseIcon,
  CreateChatWrapper,
  Images,
  Label,
  LoaderWrapper,
  PushMessage,
  UploadLoader,
} from './styled';
import {
  DocumentationPopup,
  // Button,
  // CloseButton,
  // DocumentationPopup,
  EmojiArea,
  // FileInputControlled,
  Loader,
  // MenuList,
  Tooltip,
} from 'components';
import { Document } from 'types';
import { useTranslation } from 'react-i18next';
import {
  Control,
  ControllerRenderProps,
  DeepMap,
  FieldError,
  UseFormMethods,
} from 'react-hook-form';
import {
  CloseIcon,
  DeviceIcon,
  DocumentationIcon,
  FileIcon,
  GreenPlusIcon,
  LeftCloseArrow,
} from 'static';
import { Layout } from '../ChatUserList/styled';
import { HiddenInput } from 'components/Inputs/ImageInput/styled';

import { useInfoPopup } from 'hooks';
import { ChatFormValues, FORMFIELDS } from '../Chat/form';
import DropdownMenu from 'components/DropdownMenu';
import { ImageIcon, MenuItem, MenuItemWrapper } from '../Chat/styled';
import { StyledPreviewBox } from 'components/Inputs/FileInput/styled';
import { StyledPreviewItem } from 'components/Inputs/FileInputControlled/styled';
import { useSelector } from 'react-redux';
import { fileUploadDetails, presignedUrlLoader } from 'store/selectors/chat';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';

interface ChatInputProps
  extends Partial<Pick<UseFormMethods<ChatFormValues>, 'register'>> {
  errors: DeepMap<ChatFormValues, FieldError>;
  control: Control<ChatFormValues>;
  onSubmit: (type: 'document' | 'photo') => void;
  isOpen: boolean;
  sendMessageLoader: boolean;
  isActiveSend: boolean;
  communityId: string;
  attachmentsFieldProps: ControllerRenderProps<ChatFormValues>;
  clearErrors: (name?: string | string[]) => void;
  deleteGroupLoading: boolean;
  onRemoveDocument: (document: Document) => void;
  onDocumentsAttach: (documents: Document[]) => void;
  watchedValues: any;
}

const AVAILABLE_SIZE = 250 * 1024;

const ChatInput: FC<ChatInputProps> = ({
  errors,
  register,
  control,
  onSubmit,
  isOpen,
  sendMessageLoader,
  isActiveSend,
  attachmentsFieldProps,
  communityId,
  clearErrors,
  deleteGroupLoading,
  watchedValues,
  onRemoveDocument,
  onDocumentsAttach,
}) => {
  const getUrlLoader = useSelector(presignedUrlLoader);
  const uploadDetail = useSelector(fileUploadDetails);
  const [documentsPopupOpen, setDocumentsPopupOpen] = useState<boolean>(false);
  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const percentageRef = useRef<number>(0);
  const community = useSelector(getCurrentCommunity);
  const [selectedType, setSelectedType] = useState<'document' | 'photo' | null>(
    null,
  );
  const childRef = useRef<{ closeMenu: () => void }>(null);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const [fileSizeTitle, fileSizeMessage] = t('errors.fileSizeCustom', {
    size: 20,
  }).split(':');
  const { showInfoPopup } = useInfoPopup();
  const validImageSize = (imageFile: File) =>
    Math.round(imageFile.size / 1024) < AVAILABLE_SIZE;
  const documentation = community.documentation?.documents;
  useEffect(() => {
    if (!attachmentsFieldProps.value?.length) {
      setSelectedType(null);
      percentageRef.current = 0;
    }
  }, [attachmentsFieldProps.value]);

  function calculateDynamicBoost(totalFiles) {
    let percentage;

    if (totalFiles <= 5) {
      percentage = 0.375;
    } else if (totalFiles <= 10) {
      percentage = 0.075;
    } else {
      percentage = Math.max(0.05, 0.15 - (totalFiles - 10) * 0.005);
    }

    return Math.min(50, Math.max(5, 50 * percentage));
  }

  const percentage = useMemo(() => {
    if (!attachmentsFieldProps.value?.length) {
      return 0;
    }
    const totalFiles = attachmentsFieldProps.value.length;
    const calculated = (uploadDetail.uploadedCount * 100) / totalFiles;
    const dynamicBoost = calculateDynamicBoost(totalFiles);

    if (
      calculated === 0 &&
      uploadDetail.remainingFiles === null &&
      percentageRef.current === 0
    ) {
      return dynamicBoost;
    } else {
      if (calculated > 80) {
        percentageRef.current = 100;
      }
      return calculated;
    }
  }, [uploadDetail.uploadedCount, getUrlLoader]);

  const dropdownData = useMemo(
    () => [
      {
        type: 'document' as const,
        name: t('chat.op1'),
        id: 1,
        acceptedFiles: 'video/mp4,video/3gpp,video/quicktime,application/*',
        icon: <DocumentationIcon width={14} height={14} />,
      },
      {
        type: 'photo' as const,
        name: t('chat.op2'),
        id: 2,
        acceptedFiles: 'image/*, image/heic, image/HEIC, image/HEIF, ',
        icon: <DeviceIcon width={14} height={14} />,
      },
      // {
      //   type: 'photo' as const,
      //   name: 'Attach community documents',
      //   id: 3,
      //   acceptedFiles: '',
      //   icon: <DeviceIcon width={14} height={14} />,
      // },
    ],
    [],
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'document' | 'photo',
  ) => {
    const imageFiles = Array.from(e.target.files) as File[];
    inputRef.current.value = null;

    if (!imageFiles.length) return false;

    const currentAttachments = Array.isArray(
      watchedValues[FORMFIELDS.ATTACHMENTS],
    )
      ? watchedValues[FORMFIELDS.ATTACHMENTS]
      : [];

    if (currentAttachments.length + imageFiles.length > 20) {
      showInfoPopup({
        title: 'Upload Limit Exceeded',
        message: 'You can upload a maximum of 20 images or files.',
        loop: true,
        type: 'warning',
      });
      return false;
    }

    for (const imageFile of imageFiles) {
      if (!validImageSize(imageFile)) {
        showInfoPopup({
          title: fileSizeTitle,
          message: fileSizeMessage,
          loop: true,
          type: 'warning',
        });
        return false;
      }
    }

    setSelectedType(type);
    childRef.current.closeMenu();
    attachmentsFieldProps.onChange([...currentAttachments, ...imageFiles]);
  };

  useEffect(() => {
    if (!sendMessageLoader) {
      document.getElementById('message').focus();
    }
  }, [sendMessageLoader]);

  const getTooltipProps = (textKey) => ({
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
      whiteSpace: 'pre-wrap',
      lineBreak: 'normal',
    },
    text: t(textKey),
  });

  const tooltipProps = getTooltipProps('common.attachFile');
  const sendTooltipProps = getTooltipProps('auth.send');

  const removeAttachment = (
    attachments: any[],
    index: number,
    onChange: (updatedFiles: any[]) => void,
  ) => {
    const updatedFiles = attachments.filter((_, i) => i !== index);
    onChange(updatedFiles);
  };

  return (
    <CreateChatWrapper>
      <PushMessage>
        <DropdownMenu
          ref={childRef}
          trigger={
            <ButtonWrapper>
              <Tooltip {...tooltipProps} position="top-center">
                <Layout.Header.Close
                  height="40px"
                  width="40px"
                  disabled={
                    sendMessageLoader ||
                    deleteGroupLoading ||
                    sendMessageLoader ||
                    getUrlLoader
                  }
                >
                  <GreenPlusIcon width={18} height={18} />
                </Layout.Header.Close>
              </Tooltip>
            </ButtonWrapper>
          }
          position="top"
        >
          <MenuItemWrapper>
            {dropdownData.map((data) => {
              const disabled =
                selectedType === null
                  ? false
                  : selectedType === data.type
                  ? false
                  : true;

              return (
                <MenuItem key={data.id} disabled={disabled}>
                  <ImageIcon>{data.icon}</ImageIcon>
                  <Label name={data.type}>{data.name}</Label>
                  <HiddenInput
                    type="file"
                    name={data.type}
                    onChange={(e) => handleChange(e, data.type)}
                    accept={data.acceptedFiles}
                    ref={inputRef}
                    multiple
                    disabled={disabled}
                  />
                </MenuItem>
              );
            })}
          </MenuItemWrapper>
        </DropdownMenu>
        <DocumentationPopup
          isOpen={documentsPopupOpen}
          data={documentation}
          selectedDocuments={selectedDocuments}
          groups={community.groups}
          setSelectedDocuments={setSelectedDocuments}
          onRemoveDocument={onRemoveDocument}
          onSubmit={onDocumentsAttach}
          closePopup={() => setDocumentsPopupOpen(false)}
        />
        <PushMessage.Input isOpen={isOpen}>
          <EmojiArea
            resize
            disabled={sendMessageLoader || deleteGroupLoading || getUrlLoader}
            name={FORMFIELDS.MESSAGE}
            label={''}
            error={errors[FORMFIELDS.MESSAGE]}
            register={register}
            control={control}
            placeholder={t('chat.inputPlaceHolder')}
            positionRight="6px"
            top="-325px"
            width="100%"
            positionTop="1px"
            rows={3}
            withEmojiIcon
            countFontSize={10}
            countFontWeight={400}
            fontSize="10"
            fontWeightLabel={400}
          />
          {percentage > 0 ? (
            <LoaderWrapper>
              <UploadLoader progress={percentage ? percentage : 0} />
              <p>{percentage.toFixed(2) + '%'}</p>
            </LoaderWrapper>
          ) : null}
          {attachmentsFieldProps.value?.length > 0 && (
            <Images
              disabled={
                sendMessageLoader ||
                errors[FORMFIELDS.MESSAGE] ||
                deleteGroupLoading ||
                getUrlLoader
              }
              isError={errors[FORMFIELDS.MESSAGE]}
            >
              <PerfectScrollbar
                style={{
                  maxWidth: '100%',
                  height: '100%',
                }}
                options={{
                  wheelSpeed: 0.4,
                  wheelPropagation: false,
                  minScrollbarLength: 4,
                  useBothWheelAxes: true,
                }}
              >
                <StyledPreviewBox>
                  {Array.isArray(attachmentsFieldProps.value) ? (
                    attachmentsFieldProps.value.map((i, index) => (
                      <StyledPreviewItem key={i.name}>
                        <StyledPreviewItem.DeleteButtonWrapper
                          onClick={() =>
                            removeAttachment(
                              attachmentsFieldProps.value,
                              index,
                              attachmentsFieldProps.onChange,
                            )
                          }
                        >
                          <CloseIcon />
                        </StyledPreviewItem.DeleteButtonWrapper>
                        <FileIcon />
                        <StyledPreviewItem.FileName>
                          {i.name}
                        </StyledPreviewItem.FileName>
                      </StyledPreviewItem>
                    ))
                  ) : (
                    <StyledPreviewItem />
                  )}
                </StyledPreviewBox>
              </PerfectScrollbar>
            </Images>
          )}
        </PushMessage.Input>
        {!getUrlLoader ? (
          <ButtonWrapper>
            <Tooltip {...sendTooltipProps} position="top-center">
              <Layout.Header.Close
                disabled={
                  sendMessageLoader ||
                  errors[FORMFIELDS.MESSAGE] ||
                  deleteGroupLoading ||
                  getUrlLoader
                }
                height="40px"
                width="40px"
                rotate
                onClick={() => {
                  onSubmit(selectedType);
                }}
                isActive={isActiveSend}
                activeColor
              >
                {sendMessageLoader ? (
                  <Loader size="28px" type="button" />
                ) : (
                  <LeftCloseArrow width={28} height={28} />
                )}
              </Layout.Header.Close>
            </Tooltip>
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <Count>
              {!uploadDetail.totalFiles || !uploadDetail.uploadedCount
                ? '1' + '/' + attachmentsFieldProps.value?.length
                : uploadDetail.uploadedCount +
                  '/' +
                  attachmentsFieldProps.value?.length}
            </Count>
          </ButtonWrapper>
        )}
      </PushMessage>
    </CreateChatWrapper>
  );
};

export default ChatInput;
