import styled from 'styled-components';

import { breakpoints } from 'theme';

export const SidebarFooter = styled.div`
  width: 100%;
  height: 61px;
  position: absolute;
  padding-right: 17px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  border-top: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  background-color: white;
  z-index: 100;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
  height: ${({ fullHeight, height }) =>
    fullHeight ? 'calc(100svh - 51px)' : height || 'calc(100svh - 190px)'};
  @media ${breakpoints.downSmPlus} {
    height: ${({ fullHeight, smHeight }) =>
      fullHeight ? 'calc(100svh - 51px)' : smHeight || 'calc(100svh - 190px)'};
  }

  .ReactVirtualized__Masonry {
    overflow: auto !important;
    padding: 16px 12px !important;
  }
`;

export const SidebarInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 20px;
  gap: ${({ gap }) => gap || '0px'};
  @media ${breakpoints.downSmPlus} {
    padding-bottom: 100px;
  }
`;

export const DefaultResFiledWrapper = styled.div``;
export const TextWrapper = styled.div``;
export const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TextH3 = styled.h2`
  color: #304659;
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 12px;
`;
export const TextP = styled.p`
  color: #8298ab;
  font-weight: 400;
  line-height: normal;
  -webkit-letter-spacing: -0.011em !important;
  -moz-letter-spacing: -0.011em !important;
  -ms-letter-spacing: -0.011em !important;
  letter-spacing: -0.011em !important;
  font-size: 14px;
`;

export const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50%;
  z-index: 0;
`;

export const Responses = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
  z-index: 0;
`;

export const StyledLabel = styled.label`
  margin: ${({ zoom = 1, noMargin }) =>
    noMargin
      ? 0
      : `${Math.floor(10 * zoom)}px 0 ${Math.floor(2 * zoom)}px ${Math.floor(
          10 * zoom,
        )}px`};
  font-size: ${({ zoom = 1, fontSize = 12 }) => Math.floor(fontSize * zoom)}px;
  color: ${({ error, theme }) =>
    error ? theme.color.bittersweet : theme.color.baliHai};
  display: flex;
  align-items: center;
  text-align: ${({ labelAlignment = 'center' }) => labelAlignment};
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  gap: 10px;
  border-radius: 4px;
`;

export const SidebarImageInputWrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 26px;
`;

export const OverflowSidebarContent = styled(SidebarContent)`
  overflow: auto;
  height: calc(100svh - 112px);

  @media ${breakpoints.downSmPlus} {
    padding-bottom: 100px;
  }
`;

export const OverflowSidebarContentWithTabs = styled(SidebarContent)`
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 155px);
    padding-bottom: 100px;
  }
`;
