import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GROUP_ROLES } from 'configs';
import { getProfileName, hasCommunityOrGroupAdminPermissions } from 'utils';
import { CommunityMembersByGroups, CommunityRoles, CommunityUser } from 'types';

import { StyledAvatarNoPhoto } from 'sidebars/CreateEventOverall/styled';
import { toast } from 'react-hot-toast';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { useSelector } from 'react-redux';

export const useParticipantsValues = (
  value: string[] = [],
  groups: CommunityMembersByGroups[],
  communityUsers: CommunityUser[],
  onChange: (value: string[]) => void = () => {},
  forChat?: boolean,
) => {
  const community = useSelector(getCurrentCommunity);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const { t } = useTranslation();

  const filteredUsers = communityUsers.filter(
    (u) => u.communityPermission?.role !== CommunityRoles.BLOCKED,
  );

  const [searchParticipantsInputValue, setSearchParticipantsInputValue] =
    useState('');
  const [allParticipantsChecked, setAllParticipantsChecked] = useState(
    value.length === communityUsers.length,
  );
  const [gridData, setGridData] = useState([]);

  const selectedParticipants = useMemo(
    () =>
      filteredUsers.reduce((acc, cur) => {
        acc[cur.id] = value.includes(cur.id);

        return acc;
      }, {}),
    [communityUsers, value],
  );

  useEffect(() => {
    if (groups) {
      setGridData([...getGroupsData(groups, filteredUsers)]);
    }
  }, [groups, communityUsers]);

  const communityUsersWithGroup = groups
    ? groups
        .map((group) => group.users)
        .flat()
        .map((user) => {
          return {
            ...user,
            communityPermission: filteredUsers.find((u) => u.id === user.id)
              ?.communityPermission,
          };
        })
    : [];

  const usersWitoutGroup = {};
  const communityUsersByGroups = {};

  filteredUsers
    .filter(({ id }) => !communityUsersWithGroup.some((item) => item.id === id))
    .map((user) => {
      filteredUsers.map((commUser) => {
        if (commUser.profile.id === user.profile.id) {
          return {
            ...commUser,
            communityPermission: user.communityPermission,
          };
        }
      });
      usersWitoutGroup[user.id] = value.includes(user.id);
    });

  groups &&
    groups.map((group) => {
      const { users } = group;
      const usersObj = {};

      if (users.length) {
        users.map((user) => {
          usersObj[user.id] = value.includes(user.id);
        });

        communityUsersByGroups[group.id] = usersObj;
      }
    });

  if (Object.keys(usersWitoutGroup).length) {
    communityUsersByGroups['no_group'] = usersWitoutGroup;
  }

  const selectParticipant = useCallback(
    (id) => {
      if (
        value.includes(id) &&
        hasCommunityOrGroupAdminPermissions(id, community)
      ) {
        const data = value.filter((element) =>
          hasCommunityOrGroupAdminPermissions(element, community),
        );
        if (data.length === 1) {
          toast.error('cant remove last admin');
          return;
        }
      }
      onChange(
        value.includes(id) ? value.filter((i) => i !== id) : [...value, id],
      );
    },
    [value],
  );

  const handleSelectAllParticipantsClick = useCallback(() => {
    if (allParticipantsChecked) {
      onChange(
        forChat &&
          hasCommunityOrGroupAdminPermissions(currentUser.id, community)
          ? [currentUser.id]
          : [],
      );
    } else {
      const updatedUsers = filteredUsers.map((u) => u.id);
      onChange(
        forChat && !updatedUsers.includes(currentUser.id)
          ? [...updatedUsers, currentUser.id]
          : updatedUsers,
      );
    }

    setAllParticipantsChecked(!allParticipantsChecked);
  }, [allParticipantsChecked, filteredUsers, forChat, onChange]);

  const selectGroup = useCallback(
    (groupId) => {
      const groupUsers = communityUsersByGroups[groupId];
      const allSelected = Object.keys(groupUsers).every(
        (key) => !!selectedParticipants[key],
      );
      const updatedUsers = {};

      Object.keys(groupUsers).forEach((key) => {
        if (
          key === currentUser.id &&
          hasCommunityOrGroupAdminPermissions(currentUser.id, community)
        ) {
          updatedUsers[key] = true;
        } else {
          updatedUsers[key] = !allSelected;
        }
      });

      const result = {
        ...selectedParticipants,
        ...updatedUsers,
      };

      onChange(Object.keys(result).filter((k) => !!result[k]));
    },
    [selectedParticipants],
  );

  const selectAllCommunityAdmins = useCallback(() => {
    const communityAdminsIds = communityUsers
      .filter(
        (u) =>
          u.communityPermission?.role === CommunityRoles.OWNER ||
          u.communityPermission?.role === CommunityRoles.ADMIN,
      )
      .map((u) => u.id);
    const allSelected = communityAdminsIds.every(
      (id) => !!selectedParticipants[id],
    );
    const updatedUsers = {};

    communityAdminsIds.forEach((id) => {
      if (
        id === currentUser.id &&
        hasCommunityOrGroupAdminPermissions(currentUser.id, community)
      ) {
        updatedUsers[id] = true;
      } else {
        updatedUsers[id] = !allSelected;
      }
    });

    const result = {
      ...selectedParticipants,
      ...updatedUsers,
    };

    onChange(Object.keys(result).filter((k) => !!result[k]));
  }, [selectedParticipants]);

  const selectAllGroupsAdmins = useCallback(() => {
    const groupAdminsIds = groups
      .map((g) => g.users)
      .flat()
      .filter(
        (u) =>
          u.groupPermission?.role === GROUP_ROLES.ADMIN ||
          u.groupPermission?.role === GROUP_ROLES.GROUP_LEADER,
      )
      .map((u) => u.id);
    const groupAdmins = [...new Set(groupAdminsIds)];
    const allSelected = groupAdmins.every((id) => !!selectedParticipants[id]);
    const updatedUsers = {};

    groupAdmins.forEach((id) => {
      if (
        id === currentUser.id &&
        hasCommunityOrGroupAdminPermissions(currentUser.id, community)
      ) {
        updatedUsers[id] = true;
      } else {
        updatedUsers[id] = !allSelected;
      }
    });

    const result = {
      ...selectedParticipants,
      ...updatedUsers,
    };

    onChange(Object.keys(result).filter((k) => !!result[k]));
  }, [selectedParticipants]);

  const getGroupsData = (
    groupsArr: CommunityMembersByGroups[],
    communityUsers: CommunityUser[],
  ) => {
    const groups = Array.isArray(groupsArr) ? groupsArr : [];
    let groupsData = groups
      .filter((group) => group.users.length)
      .map((group) => [
        {
          type: 'group',
          id: group.id,
          label: group.name,
        },
        ...group.users.map((member) => ({
          type: 'user',
          id: member.profile.userId,
          group: group.id,
          label: getProfileName(member.profile),
          icon: member.profile.smallLogo || member.profile.logo || (
            <StyledAvatarNoPhoto>
              {getProfileName(member.profile)[0].toUpperCase()}
            </StyledAvatarNoPhoto>
          ),
          firstName: member.profile.firstName,
          lastName: member.profile.lastName,
          profileName: member.profile.profileName,
          email: member.profile.email,
        })),
      ])
      .flat();
    const usersWitoutGroup = communityUsers.filter(
      ({ id }) => !groupsData?.some((item) => item.id === id),
    );

    if (usersWitoutGroup.length) {
      groupsData = groupsData.concat([
        {
          type: 'group',
          id: 'no_group',
          label: t('common.withoutGroup'),
        },
        ...usersWitoutGroup.map((member) => ({
          type: 'user',
          id: member.id,
          group: 'no_group',
          label: getProfileName(member.profile),
          icon: member.profile.smallLogo || member.profile.logo || (
            <StyledAvatarNoPhoto>
              {getProfileName(member.profile)[0].toUpperCase()}
            </StyledAvatarNoPhoto>
          ),
          firstName: member.profile.firstName,
          lastName: member.profile.lastName,
          profileName: member.profile.profileName,
          email: member.profile.email,
        })),
      ]);
    }

    return groupsData;
  };

  const allCommunityAdminsChecked = useMemo(() => {
    const communityAdmins = communityUsers.filter(
      (u) =>
        u.communityPermission?.role === CommunityRoles.OWNER ||
        u.communityPermission?.role === CommunityRoles.ADMIN,
    );

    return (
      communityAdmins.length > 0 &&
      communityAdmins.every((u) => !!selectedParticipants[u.id])
    );
  }, [communityUsers, selectedParticipants]);

  const allGroupAdminsChecked = useMemo(() => {
    const groupsAdmins = groups
      ?.map((g) => g.users)
      .flat()
      .filter(
        (u) =>
          u.groupPermission?.role === GROUP_ROLES.ADMIN ||
          u.groupPermission?.role === GROUP_ROLES.GROUP_LEADER,
      );

    return (
      groupsAdmins?.length > 0 &&
      groupsAdmins.every((u) => !!selectedParticipants[u.id])
    );
  }, [groups, selectedParticipants]);

  return {
    communityUsersWithGroup,
    selectedParticipants,
    selectParticipant,
    selectGroup,
    searchParticipantsInputValue,
    setSearchParticipantsInputValue,
    handleSelectAllParticipantsClick,
    selectAllCommunityAdmins,
    selectAllGroupsAdmins,
    allParticipantsChecked: Object.values(selectedParticipants).every(
      (item) => !!item,
    ),
    allCommunityAdminsChecked,
    allGroupAdminsChecked,
    gridData,
  };
};
