import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  Button,
  SidebarFooter,
  SidebarContent,
  SidebarHeader,
  Loader,
  CommunityUsersByGroups,
} from 'components';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import {
  getCurrentCommunity,
  getCurrentCommunityLoading,
} from 'store/selectors/currentCommunity';
import { AddGroupMembersData } from 'types';
import { Chat, ChatUser, Conversation } from 'store/reducers/chat';
import {
  doCreateChatGroup,
  doUpdateChatGroup,
} from 'store/actionCreators/chat';

import {
  createChatGroupLoading,
  updateChatGroupLoading,
} from 'store/selectors/chat';
import { getMyId } from 'store/selectors/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { SkeletonListWrapper } from 'sidebars/AboutGroup/styled';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';
import { hasCommunityOrGroupAdminPermissions } from 'utils';

const AddGroupMembersChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const userId = useSelector(getMyId);
  const { communityId: paramsCommunityId } = useParams<{
    communityId: string;
  }>();
  const currentCommunityLoading = useSelector(getCurrentCommunityLoading);
  const createChat = useSelector(createChatGroupLoading);
  console.log('currentCommunityLoading', currentCommunityLoading);

  const data = useSelector(getData) as AddGroupMembersData & {
    chat?: Chat;
    members?: ChatUser[];
    isUpdate?: boolean;
    communityId: string;
    description?: string;
    chatName?: string;
    logo?: string;
  };
  const { chat, isUpdate, communityId, members, chatName, logo, description } =
    data;

  const community = useSelector(getCurrentCommunity);
  const updateGroupLoading = useSelector(updateChatGroupLoading);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isUpdate) {
      setSelectedUsers(userId ? [userId] : []);
    } else {
      setSelectedUsers(members.map((m) => m.userId));
    }
  }, [members, isUpdate]);

  const filteredMembersByGroups = useMemo(() => {
    if (community?.groups) {
      return (
        community.groups.map((g) => ({
          ...g,
          users: g.users,
        })) || []
      );
    }

    return [];
  }, [community?.groups, paramsCommunityId]);

  useEffect(() => {
    if (selectedUsers.length > 0 && filteredMembersByGroups.length > 0) {
      setIsLoading(false);
    }
  }, [selectedUsers, filteredMembersByGroups]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  const onSuccess = () => {
    handleClose();
    addToast(t('chat.update.success'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSuccessChat = (chatData: Conversation) => {
    dispatch(closeModal());

    addToast(t('chat.create.success'), {
      appearance: 'success',
      autoDismiss: true,
    });

    if (paramsCommunityId) {
      navigate(
        `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}?talkId=${chatData.id}`,
      );
    } else {
      navigate(
        `${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}?talkId=${chatData.id}`,
      );
    }
  };

  const onSubmit = useCallback(() => {
    if (!isUpdate) {
      dispatch(
        doCreateChatGroup({
          chatName: chatName,
          description: description,
          logo: logo,
          members: selectedUsers,
          onSuccess: onSuccessChat,
          communityId,
          admin: selectedUsers.filter((element) =>
            hasCommunityOrGroupAdminPermissions(element, community),
          ),
        }),
      );
    } else {
      dispatch(
        doUpdateChatGroup.trigger({
          userId,
          chatId: chat.id,
          communityId,
          payload: {
            chatName: chat.chatName,
            description: chat.description,
            logo: chat.logo,
            members: selectedUsers,
            admin: selectedUsers.filter((element) =>
              hasCommunityOrGroupAdminPermissions(element, community),
            ),
          },
          onSuccess,
        }),
      );
    }
  }, [selectedUsers, chat, communityId]);

  const hasNoUsers = community?.users?.length <= 0;
  const hasNoFilteredMembers = filteredMembersByGroups.length <= 0;

  if (hasNoUsers || hasNoFilteredMembers) {
    return;
  }

  return (
    <Sidebar>
      <SidebarHeader
        label={isUpdate ? 'Update Participants' : t('group.addParticipants')}
        onCloseClick={handleClose}
      />
      <SidebarContent fullHeight>
        {currentCommunityLoading || isLoading ? (
          <SkeletonListWrapper>
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="skeleton skeleton-list" />
            ))}
          </SkeletonListWrapper>
        ) : (
          <CommunityUsersByGroups
            value={selectedUsers}
            membersByGroups={filteredMembersByGroups}
            users={community?.users}
            height="calc(100svh - 269px)"
            onChange={setSelectedUsers}
            key={'memberSection'}
            forChat
          />
        )}
      </SidebarContent>
      <SidebarFooter>
        <Button
          disabled={isEmpty(community.users) || updateGroupLoading}
          onClick={onSubmit}
        >
          {updateGroupLoading || createChat ? (
            <Loader type="button" size="28px" thickness={2} />
          ) : isUpdate ? (
            t('chat.update.btn')
          ) : (
            t('chat.update.btn')
          )}
        </Button>
      </SidebarFooter>
    </Sidebar>
  );
};

export default AddGroupMembersChat;
