// import { createRoutine } from 'redux-saga-routines';

import { createRoutine } from 'redux-saga-routines';
import {
  GetPresignedUrl,
  SendMessagePayload,
  UpdateChatGroupPayload,
} from 'services/chat';
import { Conversation } from 'store/reducers/chat';

export const doGetChatUsers = createRoutine('GET_CHAT_USERS', {
  trigger: (payload: {
    communityId?: string;
    userId: string;
    type: boolean;
    forNotification?: boolean;
  }) => payload,
});

export const doGetChatSearchUsers = createRoutine('GET_CHAT_SEARCH_USERS', {
  trigger: (payload: {
    userId: string;
    search: string;
    communityId?: string;
    type: boolean;
  }) => payload,
});

export const doUpdateChatUsers = createRoutine('UPDATE_CHAT_USERS', {
  trigger: (payload: { userId: string }) => payload,
});

export const doSendMessage = createRoutine('SEND_MESSAGE', {
  trigger: (payload: SendMessagePayload) => payload,
});

export const doGetPresignedUrl = createRoutine('GET_PRESIGNED_URL', {
  trigger: (payload: GetPresignedUrl) => payload,
});

export const doClearFiles = createRoutine('CLEAR_FILES');

export const doUpdateUpdateCount = createRoutine('UPDATE_COUNT');

export const doUpdateChatGroup = createRoutine('UPDATE_CHAT_GROUP', {
  trigger: (payload: {
    userId: string;
    chatId: string;
    payload: UpdateChatGroupPayload;
    onSuccess: () => void;
    communityId: string;
  }) => payload,
});

export const doDeleteChatGroup = createRoutine('DELETE_CHAT_GROUP', {
  trigger: (payload: {
    userId: string;
    chatId: string;
    onSuccess: () => void;
  }) => payload,
});

export const doSetConversation = createRoutine('SET_CONVERSATION');

export const doGetConversation = createRoutine('GET_CONVERSATION', {
  trigger: (payload: {
    userId: string;
    receiverId?: string;
    count?: number;
    chatId?: string;
    page?: number;
    limit?: number;
  }) => payload,
});

export const doUpdateConversation = createRoutine('UPDATE_CONVERSATION', {
  trigger: (payload: {
    userId: string;
    receiverId?: string;
    count?: number;
    chatId?: string;
    page?: number;
    limit?: number;
  }) => payload,
});

export const doAddMembersToGroup = createRoutine('ADD_MEMBERS_TO_GROUP', {
  trigger: (payload: { members: string[]; groupChatId: string }) => payload,
});

export const doCreateChatGroup = createRoutine('CREATE_CHAT_GROUP', {
  trigger: (payload: {
    chatName: string;
    description: string;
    logo: string | null;
    members: string[];
    onSuccess: (chat: Conversation) => void;
    communityId: string;
    admin: string[];
  }) => payload,
});
