import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getIsMenuOpen,
  getMembersToGroupsLoading,
} from 'store/selectors/currentCommunity';
import { doGetCommunityMembersByGroups } from 'store/actionCreators/currentCommunity';
import { getMyEventsLoaders } from 'store/selectors/events';
import { ContentWrapper, Loader } from 'components';
import EmailModuleHeader from './Header';
import EmailModuleRecipients from './Recipients';
import EmailModuleSelectEvent from './SelectEvent';
import EmailModuleMessage from './Message';
import { hasCommunityOrGroupAdminPermissions } from 'utils';
import { useEmailModuleForm, FIELDS, EmailModuleFormValues } from './form';
import { sendEmail } from 'services/mail';
import { breakpoints } from 'theme';
import { MyAppointmentsEvent } from 'types';

import { GridWrapper, ContentContainer, StyledFloatingLoader } from './styled';
import { useMedia } from 'react-use';
import { download } from 'utils/common';

const EmailModulePageContainer = () => {
  const { t } = useTranslation();
  const isDownMdPlus = useMedia(breakpoints.downMdPlus);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [selectedEvent, setSelectedEvent] = useState<MyAppointmentsEvent>(null);
  const [eventResponsesFilter, setEventResponsesFilter] = useState<
    {
      [p: string]: any;
    }[]
  >([]);
  const mailWrapperRef = useRef<HTMLDivElement | null>(null);
  const [sending, setSending] = useState(false);
  const community = useSelector(getCurrentCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const loaders = useSelector(getMyEventsLoaders);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const membersToGroupsLoading = useSelector(getMembersToGroupsLoading);

  useEffect(() => {
    dispatch(
      doGetCommunityMembersByGroups({
        communityId: community.id,
        withoutBlocked: true,
      }),
    );
  }, []);

  useEffect(() => {
    const modalRefVal = mailWrapperRef.current;

    const handleOutSideClick = (event: MouseEvent) => {
      if (modalRefVal && modalRefVal.contains(event.target as Node)) {
        event.preventDefault();
        event.stopPropagation();
        addToast(t('errors.onlyForGroupAndAdmin'), {
          appearance: 'info',
          autoDismiss: true,
        });
      }
    };
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id, community)) {
      document.addEventListener('mousedown', handleOutSideClick, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
    };
  }, []);

  const defaultValues: EmailModuleFormValues = {
    [FIELDS.SEND_TO_ME]: true,
    [FIELDS.RECIPIENTS]: [],
    [FIELDS.SUBJECT]: '',
    [FIELDS.MESSAGE]: '',
    [FIELDS.OTHER_RECIPIENTS]: '',
    [FIELDS.ATTACHMENTS]: [],
  };

  const {
    errors,
    setError,
    handleSubmit,
    register,
    setValue,
    values,
    attachmentsFieldProps,
    recipientsFieldProps,
    sendToMeFieldProps,
  } = useEmailModuleForm(defaultValues);

  const handleRecipientsChange = (value: string[]): void => {
    const selectedUsers = value
      .map((id) => community.users.find((u) => u.id === id)?.id)
      .filter((i) => i);

    recipientsFieldProps.onChange(selectedUsers);
  };

  const submitForm = () => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }
    setSending(true);

    handleSubmit(
      async (data) => {
        const recipients = data.recipients
          .map((id) => community.users.find((u) => u.id === id)?.profile?.email)
          .filter((i) => !!i);
        await sendEmail(community.id, { ...data, recipients }, FIELDS);

        addToast(t('email.emailSentSuccessfuly'), {
          appearance: 'success',
          autoDismiss: false,
        });
        setSending(false);
      },
      () => {
        addToast(t('errors.text500'), {
          appearance: 'error',
          autoDismiss: true,
        });
        setSending(false);
      },
    )();
  };

  const handleDocumentsAttach = async (data) => {
    const newFiles = await Promise.all(
      data
        .filter(
          (i) => !values[FIELDS.ATTACHMENTS].some((f) => f.name === i.name),
        )
        .map((i) => download(i.url, i.name)),
    );

    return attachmentsFieldProps.onChange([
      ...values[FIELDS.ATTACHMENTS],
      ...newFiles,
    ]);
  };

  const handleRemoveDocument = (document: any) => {
    attachmentsFieldProps.onChange(
      values[FIELDS.ATTACHMENTS].filter((i) => i.name !== document.name),
    );
  };

  const handleSendToMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    sendToMeFieldProps.onChange(e.target.checked);
  };

  const getRecipientsFilteredByReplies = (
    selectedEvent: MyAppointmentsEvent,
    currentRecipients: string[],
    filters: { [p: string]: any },
  ) => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }
    const recipients = selectedEvent.recipients;
    const isAwaiting = filters.find((i) => i.value === 'awaiting');
    const isPenalty = filters.find((i) => i.value === 'penalty');
    const isAttended = filters.find((i) => i.value === 'attended');
    const eventReplies = selectedEvent.subEventReplies;

    let users = [];

    if (filters.length === 0) {
      return [...new Set(currentRecipients.concat(selectedEvent.recipients))];
    }

    if (isAwaiting) {
      const usersWithoutReplies = recipients.filter(
        (id) => !eventReplies.some((r) => r.userId === id),
      );

      users = users.concat(usersWithoutReplies);
    }

    if (isPenalty) {
      const usersWithPenalty = eventReplies
        .filter((r) => r.penalty)
        .map((r) => r.userId);

      users = users.concat(usersWithPenalty);
    }

    if (isAttended) {
      const usersWithAttended = eventReplies
        .filter((r) => r.attended)
        .map((r) => r.userId);

      users = users.concat(usersWithAttended);
    }

    const eventRepliesIds = filters
      .filter((i) => !['penalty', 'attended', 'awaiting'].includes(i.value))
      .map((i) => i.value);

    if (eventRepliesIds.length) {
      const otherUsers = eventReplies
        .filter((r) => eventRepliesIds.includes(r.eventResponseId))
        .map((r) => r.userId);

      users = users.concat(otherUsers);
    }

    const uniqueRecipients = [...new Set(users)];
    const recipientsToRemove = recipients.filter(
      (id) => !uniqueRecipients.includes(id),
    );

    return [...new Set(currentRecipients.concat(uniqueRecipients))].filter(
      (id) => !recipientsToRemove.includes(id),
    );
  };

  const handleEventClick = (event: MyAppointmentsEvent) => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }
    if (event.id === selectedEvent?.id) {
      handleRecipientsChange(
        values[FIELDS.RECIPIENTS].filter(
          (id) => !event.recipients.includes(id),
        ),
      );
      setValue(
        FIELDS.SUBJECT,
        values[FIELDS.SUBJECT].replace(`${event.title}: `, ''),
      );

      setEventResponsesFilter([]);
      return setSelectedEvent(null);
    }

    const updatedRecipients = getRecipientsFilteredByReplies(
      event,
      values[FIELDS.RECIPIENTS],
      eventResponsesFilter,
    );

    handleRecipientsChange(updatedRecipients);

    if (selectedEvent) {
      setValue(
        FIELDS.SUBJECT,
        `${values[FIELDS.SUBJECT].replace(
          `${selectedEvent.title}`,
          `${event.title}`,
        )}`,
      );
    } else {
      setValue(FIELDS.SUBJECT, `${event.title}: ${values[FIELDS.SUBJECT]}`);
    }

    setSelectedEvent(event);
  };

  const handleEventResponsesChange = (responses: { [p: string]: any }[]) => {
    const updatedRecipients = getRecipientsFilteredByReplies(
      selectedEvent,
      values[FIELDS.RECIPIENTS],
      responses,
    );

    setEventResponsesFilter(responses);
    handleRecipientsChange(updatedRecipients);
  };

  const handleOnWrapperClick = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
  };

  if (!community || !currentUser) {
    return <Loader />;
  }

  return (
    <ContentWrapper isMenuOpen={isMenuOpen} minHeight={'auto'}>
      {loaders.events && (
        <StyledFloatingLoader>
          <Loader size="60px" type={null} />
        </StyledFloatingLoader>
      )}
      <EmailModuleHeader isMenuOpen={isMenuOpen} />
      <GridWrapper ref={mailWrapperRef}>
        {!isDownMdPlus ? (
          <EmailModuleRecipients
            currentUserId={currentUser.id ?? ''}
            handleOnWrapperClick={handleOnWrapperClick}
            community={community}
            selectedRecipients={values[FIELDS.RECIPIENTS]}
            membersToGroupsLoading={membersToGroupsLoading}
            membersByGroups={community.membersByGroups}
            users={community.users}
            onChange={handleRecipientsChange}
          />
        ) : null}
        <ContentContainer>
          <EmailModuleSelectEvent
            currentUserId={currentUser.id ?? ''}
            handleOnWrapperClick={handleOnWrapperClick}
            selectedEvent={selectedEvent}
            eventResponsesFilter={eventResponsesFilter}
            community={community}
            isLoading={loaders.events}
            isMenuOpen={isMenuOpen}
            onEventResponsesChange={handleEventResponsesChange}
            onEventClick={handleEventClick}
          />
          <EmailModuleMessage
            handleOnWrapperClick={handleOnWrapperClick}
            attachmentsFieldProps={attachmentsFieldProps}
            selectedRecipients={values[FIELDS.RECIPIENTS]}
            setSelectedParticipants={handleRecipientsChange}
            errors={errors}
            setError={setError}
            sending={sending}
            register={register}
            documentation={community.documentation?.documents}
            sendToMe={values[FIELDS.SEND_TO_ME]}
            onSendToMeChange={handleSendToMeChange}
            onRemoveDocument={handleRemoveDocument}
            onDocumentsAttach={handleDocumentsAttach}
            submitForm={submitForm}
          />
        </ContentContainer>
      </GridWrapper>
    </ContentWrapper>
  );
};

export default EmailModulePageContainer;
