import styled from 'styled-components';

export const StyledWrapper = styled.label`
  width: ${({ width }) => width || '500px'};
  height: ${({ height }) => height || '220px'};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed
    ${({ theme, isError }) =>
      isError ? theme.color.bittersweet : theme.color.brightTurquoise};
  border-radius: 8px;
  opacity: 80%;
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 100%;
  }
`;

export const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  cursor: pointer;
`;

export const StyledPreviewBox = styled.div`
  display: flex;
  position: relative;
  z-index: 100;
`;

export const StyledPreviewItem = styled.div`
  position: relative;
  margin: 10px 5px 0 5px;

  svg {
    width: 23px;
    height: 30px;

    g {
      fill: ${({ theme }) => theme.color.brightTurquoise};
    }
  }
`;

StyledPreviewItem.DeleteButtonWrapper = styled.div`
  position: absolute;
  right: 7px;
  top: -6px;
  width: 16px;
  height: 16px;
  background: #10ccc685;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease;
  &:hover {
    transform: scale(1.5);
  }
  svg {
    width: 8px;
    height: 8px;
    position: relative;
    top: -5px;

    g {
      fill: ${({ theme }) => theme.color.bittersweet};
    }
  }
`;

StyledPreviewItem.FileName = styled.div`
  font-size: 12px;
  max-width: 60px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledErrorMessage = styled.p`
  color: ${({ theme }) => theme.color.bittersweet};
  text-align: left;
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  left: 0;
`;
