import styled, { keyframes } from 'styled-components';
import { breakpoints } from 'theme';

export const CreateChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const PushMessage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  padding: 12px 12px 28px 12px;
  justify-content: center;
`;
export const Images = styled.div`
  display: inline-block;
  width: 100%;
  border: 1px dashed
    ${({ theme, isError }) =>
      isError ? theme.color.bittersweet : theme.color.brightTurquoise};
  border-radius: 6px;
  padding-bottom: 6px;

  ${({ disabled }) =>
    disabled && {
      opacity: '0.6',
      pointerEvents: 'none',
    }};
  p {
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: left;
  }
`;

PushMessage.Input = styled.div`
  flex: 1;
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  flex-direction: column;
  transition: all 0.35s;
  max-width: 800px;
  padding: 0px 12px 0px 12px;
  align-items: flex-start;
  @media ${breakpoints.downLgPlus} {
    max-width: ${({ isOpen }) => (isOpen ? '520px' : '620px')};
  }
  gap: 10px;
  textarea {
    border-width: 2px;
  }
  & > div:first-child {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  margin-top: 19px;
  width: auto;
  gap: 18px;
`;

export const Count = styled.div`
  width: 40px;
  display: flex;
  font-size: 14px;
  height: 40px;
  border-radius: 99999px;
  outline: none;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const Label = styled.label`
  flex-direction: column;
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;

const coli1 = keyframes`
  0% {
    transform: rotate(-45deg) translateX(0px);
    opacity: 0.7;
  }
  100% {
    transform: rotate(-45deg) translateX(-45px);
    opacity: 0;
  }
`;

const coli2 = keyframes`
  0% {
    transform: rotate(45deg) translateX(0px);
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) translateX(-45px);
    opacity: 0.7;
  }
`;
export const LoaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
  p {
    left: 50%;
    position: absolute;
    font-size: 11px;
    width: ${({ theme }) => theme.color.pickledBluewood};
  }
`;

export const UploadLoader = styled.div`
  width: ${(props) => (props.progress ? props.progress : 8)}%;
  height: 16px;
  display: inline-block;
  position: relative;
  background: #33aba7;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  transition: width 0.8s ease;
`;
