import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ADD_GROUP_MEMBERS } from 'constants/sidebars';
import { openModal, closeModal } from 'store/actionCreators/modal';
import { doCreateGroup } from 'store/actionCreators/groups';
import { FIELDS, useCreateGroupForm } from './form';
import {
  doCreateChatGroup,
  doUpdateChatGroup,
} from 'store/actionCreators/chat';
import { useSelector } from 'react-redux';
import { getMyId } from 'store/selectors/auth';
import { Chat, ChatUser, Conversation } from 'store/reducers/chat';
import { ModalTypes } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';

export const useCreateGroup = (
  communityId: string,
  chat?: boolean,
  defaultValues?: Chat | null,
  members?: ChatUser[],
  isChatUpdate?: boolean,
  admin: string[] = [],
) => {
  console.log('admin', { admin });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userId = useSelector(getMyId);
  const { communityId: communityIdFromParams } = useParams<{
    communityId: string;
  }>();
  const { handleSubmit, ...form } = useCreateGroupForm(chat, defaultValues);

  const onSuccess = (data) => {
    dispatch(closeModal());
    addToast(t('group.createSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
    dispatch(
      openModal({
        type: ADD_GROUP_MEMBERS,
        placement: 'right',
        data: {
          group: data,
          communityId,
        },
      }),
    );
  };

  // const onSuccessChat = (chatData: Conversation) => {
  //   dispatch(closeModal());

  //   addToast(t('chat.create.success'), {
  //     appearance: 'success',
  //     autoDismiss: true,
  //   });

  //   dispatch(
  //     openModal({
  //       type: ModalTypes.ADD_GROUP_MEMBERS_CHAT,
  //       placement: 'right',
  //       data: {
  //         chat: chatData,
  //         communityId: communityId,
  //         members: chatData.peerUsers,
  //       },
  //     }),
  //   );

  //   if (communityIdFromParams) {
  //     navigate(
  //       `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}?talkId=${chatData.id}`,
  //     );
  //   } else {
  //     navigate(
  //       `${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}?talkId=${chatData.id}`,
  //     );
  //   }
  // };

  const onSubmit = handleSubmit((data) => {
    if (isChatUpdate) {
      dispatch(
        doUpdateChatGroup({
          userId,
          chatId: defaultValues?.id,
          payload: {
            chatName: data[FIELDS.GROUP_NAME],
            description: data[FIELDS.ABOUT],
            logo: data[FIELDS.IMAGE],
            members: members?.map((member) => member.userId),
            admin: admin,
          },
          communityId,
          onSuccess: () => {
            dispatch(closeModal());
            addToast(t('chat.update.successUpdate'), {
              appearance: 'success',
              autoDismiss: true,
            });
          },
        }),
      );
    } else if (chat) {
      dispatch(
        openModal({
          type: ModalTypes.ADD_GROUP_MEMBERS_CHAT,
          placement: 'right',
          data: {
            communityId: communityId,
            chatName: data[FIELDS.GROUP_NAME],
            description: data[FIELDS.ABOUT],
            logo: data[FIELDS.IMAGE],
            chat: {
              admin: admin,
            },
          },
        }),
      );
    } else
      dispatch(
        doCreateGroup({
          communityId,
          name: data[FIELDS.GROUP_NAME],
          onSuccess,
        }),
      );
  });

  return {
    onSubmit,
    ...form,
  };
};
