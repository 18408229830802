import { createSelector } from 'reselect';
import { find } from 'lodash/fp';

// import { getGroups } from 'store/selectors/currentCommunity';
import { ChatState } from '../reducers/chat';
import { IRootState } from '../reducers';

const chatSelector = (state: IRootState): ChatState => state.chat;

export const getChatUser = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.chatUser || null,
);
export const getChatNotification = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.chatNotifications || null,
);

export const getChatUserLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.loaders.chatUserLoading,
);

export const getSelectedChatUser = (state: IRootState, talkId: string) =>
  createSelector([getChatUser], (chatUsers) =>
    find({ chatId: talkId }, chatUsers),
  )(state);

export const getSearchUserLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.loaders.searchLoading,
);

export const getChat = createSelector(
  chatSelector,
  (chat: ChatState) => chat.conversation || null,
);

export const sendMessageLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.sendMessageLoading,
);

export const createChatGroupLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.createGroupLoading,
);

export const updateChatGroupLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.updateGroupLoading,
);

export const fileUrl = createSelector(
  chatSelector,
  (chat: ChatState) => chat.fileUrl,
);

export const presignedUrlLoader = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.getPresignedUrlLoader,
);

export const fileUploadDetails = createSelector(
  chatSelector,
  (chat: ChatState) => ({
    uploadedCount: chat.fileUrl.uploadedCount,
    totalFiles: chat.fileUrl.totalFiles,
    remainingFiles: chat.fileUrl.remainingFiles,
    success: chat.fileUrl.success,
  }),
);

export const deleteChatGroupLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.deleteGroupLoading,
);

export const getIsOldChat = createSelector(
  chatSelector,
  (chat: ChatState) => chat.isOldChat,
);

// export const getPeerChatUser = createSelector(
//     chatSelector,
//     ({ conversation, chatUser }) => {
//         const id = conversation?.[0].id;
//         return chatUser.filter(
//             (user) => user.profile.userId === id,
//         ) || [];
//     },
// );

export const getConversationLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.conversationLoading,
);
export const getUpdateConversationLoading = createSelector(
  chatSelector,
  (chat: ChatState) => chat.loaders.updateConversationLoading,
);
export const getPeerUsers = createSelector(
  chatSelector,
  (chat: ChatState) => chat?.conversation?.peerUsers || [],
);
// export const getChatsLoading = createSelector(
//   chatSelector,
//   (chat: ChatState) => chat?.isLoading || null,
// );

// export const getChatsAuthenticated = createSelector(
//   chatSelector,
//   (chat: ChatState) => chat?.isAuthenticated || null,
// );

// export const getChats = createSelector(
//   chatSelector,
//   (chat: ChatState) => chat?.chats || [],
// );

// export const getChatsToView = createSelector(
//   [getChats, getGroups],
//   (chats, groups) => {
//     return chats?.filter(
//       ({ id: chatId, data }) =>
//         groups?.find(({ id: groupId }) => chatId === groupId) || data.isMain,
//     );
//   },
// );

// export const getCurrentChat = createSelector(
//   chatSelector,
//   (chat: ChatState) => chat?.currentChat || null,
// );
export {};
